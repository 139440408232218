.searchNav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -10px;
}

.searchNav-right {
    display: flex;
    width: 30%;
    margin-top: -20px;
    position: relative;
    align-items: center;
    padding: 0px 40px;
}

.searchNav-right-searchinput {
    width: 100%;
    height: 10%;
}

.searchNav-right-searchinput>input {
    width: 80%;
    height: 30px;
    border-radius: 5px;
    outline: none;
    border: 1px solid gray;
    margin-left: 20px;
}

.search-icon {
    margin-bottom: -8px;
    margin-left: -30px;
    font-weight: 500;
    cursor: pointer;
    color: gray;
}

.searchNav-right-user-icon {
    font-size: 50px!important;
    font-weight: 500!important;
}

.searchNav-left-heading-pagination {
    margin-top: -20px;
    margin-left: -5px;
}

.MuiTablePagination-actions {
    margin-left: -10px!important;
}

.MuiTypography-root {
    margin-left: -20px!important;
}

.searchNav-right-user>img {
    cursor: pointer;
}
.Sidebar {
    flex: 0.8;
    position: sticky;
    top: 0;
    height: 100vh;
    background-color: var(--themeColor);
    border-top-right-radius: 13px;
    display: flex;
    flex-direction: column;
    transition: 0.4s;
}

.Above-div {
    flex: 30%;
    background-color: var(--themeColor);
    display: grid;
    place-items: center;
}

.Above-div-avatar {
    font-size: 40px!important;
    height: 120px!important;
    width: 120px!important;
    margin-top: 15px!important;
    background-color: #FFFFFF;
}

.Lower-div {
    height: 95vh;
    flex: 80%;
    background-color: var(--themeColor);
    padding-left: 10px;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: var(--font-type);
}

.menu-Items {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    margin-top: 10px;
    padding: 5px 3px;
}

.menu-Items:hover {
    background-color: white;
    font-weight: 600;
}

.menu-Icon {
    margin-right: 5px;
}

.menu-Name {
    font-size: 18px;
    font-weight: 600;
}

.menu-Items-link {
    text-decoration: none!important;
    color: black;
}

.allMembers {
    font-size: 18px;
    font-weight: normal;
    position: relative;
}

.allMemberIcon {
    position: absolute;
    top: 6px;
    margin-left: 10%;
}

.drop-Down {
    position: absolute;
    left: 100%;
    top: 0px;
    border: 1px solid white;
    display: none!important;
    background-color: white;
    font-weight: 600;
    box-shadow: 0px 0px 4px 1px var(--themeColor);
    border-radius: 5px;
}

.drop-Down>div {
    width: auto;
    text-align: center;
    font-weight: 600;
    border-radius: 3px;
}

.allMembers:hover>.menu-Name>div {
    display: block!important;
}

.drop-Down>div {
    width: 100%;
}

.drop-Down>div:hover {
    background-color: var(--themeColor);
    font-weight: 600;
}

.Lower-div-Lower {
    display: flex;
    margin-bottom: 20px;
}

.Lower-div-Lower-Icon {
    margin-right: 10px;
    display: flex;
    align-items: center;
}

.link {
    padding: 2px 5px;
    border-radius: 5px;
}

.logout {
    font-size: 20px;
    font-weight: 600;
}

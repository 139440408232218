.student_right_side{
    flex:4;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.student_right_side_bottom{

    display: flex;
}

.student_right_side_bottom_left
{
    flex:1;
    height: 90vh;

    display: flex;
    flex-direction: column;
    align-items: center;    
}
.student_right_side_bottom_left>div
{
    cursor: pointer;
 
    padding:10px 5px!important;
    border-radius: 5px;
    margin-top: 25px;
    font-weight:600;
    width: 80%;
   
    
}


.student_right_side_bottom_right
{
    flex:4;
 
   
}
.student_right_side_bottom_right>div{

    display: none;
}



.school{
    flex: 4;
    background-color: white;
    padding: 10px 10px;
   

}
.school-parent{
    display: flex;
    width: 100%;
}
.school-left{
    flex:1;
    height: 100vh;
    position: sticky;
    top:0;
   

    
}
.school-right{
    flex:4;
 
}
.activeschoolData{

    border:1px solid rgb(223, 218, 218);

}

.schoolTab{
    display: none;
}

.schoolRight{
    padding: 20px;
}
.schoolDetails
{
    margin-top: 20px;
    height: 30px;
    width: 70%;
    cursor: pointer;
    padding:3px;    
}
.schoolDetails>h4{
    margin-top: 2px;
    margin-left: 5px;
    text-transform: capitalize;
    font-weight: 600;
}
.schoolDetails:first-child{
    border:1px solid var(--themeColor);
    border-radius: 10px ;
}


#schoolDetails{
    display: block;
   
    cursor: pointer;
}


.rightSchoolDetails{
    display: flex;
    flex-direction: column;
}
.rightSchoolDetails_UpperDiv{
    display: flex;
    width: 100%;
    justify-content: space-around;   
}
.rightSchoolDetails_MidDiv{
    width: 50%;
    display: flex;
    margin-top: 30px;
    justify-content: space-around;   
}
.rightSchoolDetails_LowerDiv{
    width: 50%;
    display: flex;
    margin-top: 30px;
  
    justify-content: space-around;   
}

.rightSchoolDetails_UpperDiv>div>span,.rightSchoolDetails_LowerDiv>div>span,.rightSchoolDetails_MidDiv>div>span{
    color: gray;
    font-size: 14px;
}

.school_breadcum{

    margin-top: 20px;
    height: 40px;
    display: flex;
    width: 100%;
    border-bottom:1px solid var(--themeColor) ;
}

.student_right_side_bottom_right_info{

    padding: 10px;
}

.upper_div_section{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    border-bottom: 1px rgb(223, 218, 218);
    padding-bottom: 10px;
}
.upper_div_section_left{

    margin-left: 20px;
    display: flex;
    align-items: center;
    font-weight: 650;   
}
.upper_div_section_right{

    display: flex;
    align-items: center;
    margin-right: 30px;
   
}
.upper_div_section_right_export_csv{
    margin-right: 20px;
    display: flex;
    align-items: center;
    font-weight: 650;   
    cursor: pointer;
}
.upper_div_section_right_sort{

    display: flex;
    align-items: center;
    font-weight: 650;   
    cursor: pointer;
}
.student_right_side_mid{
    margin: 20px 10px;
    border-bottom: 1px solid rgb(223, 218, 218);
    padding-bottom: 10px;
}
.student_breadcum{
    display: flex;
    align-items: center;
}



.rightSchoolDetails{
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    padding-top:50px;
    padding-left: 100px;
    
    height: 60vh;
    font-family: var(--font-type);
    font-weight: 600;
    font-size: 15px;
}

.rightSchoolDetails-upper{
    display: flex;
    margin-bottom: 30px;
}
.rightSchoolDetails-upper-left{
    width: 50%;
}
.rightSchoolDetails-upper-left>label{
    display: block;
    margin-bottom: 5px;
}
.rightSchoolDetails-upper-left>input{
    display: block;
    background-color: white;
    width: 95%;
    outline:none;
    height: 28px;
    border: 1px solid gray;
    border-radius: 5px;
}
.rightSchoolDetails-upper-right>label{
    display: block;
    margin-bottom: 5px;
}
.rightSchoolDetails-upper-left>input::placeholder,.rightSchoolDetails-upper-right>input::placeholder{
    padding-left: 10px;
    text-transform: capitalize;
}
.rightSchoolDetails-lower-left>input::placeholder,.rightSchoolDetails-lower-right>input::placeholder{
    padding-left: 10px;
    text-transform: capitalize;
}

.rightSchoolDetails-upper-right>input{
    display: block;
    height: 28px;
    outline:none;
    width: 95%;
    border-radius: 5px;
    border: 1px solid gray;
    background-color: white;
}
.rightSchoolDetails-upper-right{
    width: 50%;
}


.rightSchoolDetails-lower{
    display: flex;
}
.rightSchoolDetails-lower-left{
    width: 50%;
}
.rightSchoolDetails-lower-left>label{
    display: block;
    margin-bottom: 5px;
}
.rightSchoolDetails-lower-left>input{
    display: block;
    width: 95%;
    outline:none;
    border: 1px solid gray;
    border-radius: 5px;
    height: 28px;
    background-color: white;
}
.rightSchoolDetails-lower-right>label{
    display: block;
    margin-bottom: 5px;
}
.rightSchoolDetails-lower-right>input{
    display: block;
    height: 28px;
    outline:none;
    border: 1px solid gray;
    border-radius: 5px;
    width: 95%;
    background-color: white;
}
.rightSchoolDetails-lower-right{
    width: 50%;
}

.student_right_side_bottom_right_schedule{
    padding-left: 100px;
}
.below_div_section{
    margin-top: -20px;
}

.rightSchoolDetails-upper-right{
    display: flex;
    width: 48%;
    justify-content: space-between;
  

 margin-top: 4px;
}
.rightSchoolDetails-upper-right>:first-child{
  
    width: 45%;
    display: flex;
   
    flex-direction: column;
}
.rightSchoolDetails-upper-right>:first-child>select{
    height: 30px;
   
}
.rightSchoolDetails-upper-right>:last-child{
    width: 45%;
    display: flex;
    flex-direction: column;
}
.rightSchoolDetails-upper-right>:last-child>select{
    height: 30px;


}
.role{
    flex:4;
    padding: 10px;
}

.role-mid{
    display: flex;
    align-items: center;
    justify-content: space-between;
   margin-top: 10px;
    padding: 10px;
    border-bottom: 1px solid rgb(201, 200, 200);
    
}
.role-mid-left>div,.role-mid-right>div{
    display: flex;
    align-items: center;
}
.role-mid-right-item{
    margin-top: 2px;
}
.role-lower{
   margin-top: -20px;
}

.filter_dialoag{
    display: flex;
    flex-direction: column;
    width: 400px;
   
    
}
.fiter_grade{
    width:100%;
    outline:none;
   
}
.fiter_section{
    width:100%;
    outline:none;

}
.filter_grade>select,.filter_section>select{

    width:90%;
    height: 30px;
    margin-bottom: 20px;
    outline:none;


}
.filter_buttons{
    display: flex;
    width: 100%;
    justify-content: flex-end;
   
}
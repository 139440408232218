.LoginBox {
    width: 320px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    position: absolute;
    left: 0%;
    right: 0%;
    bottom: 0%;
    top: 50%;
    margin: auto;
    padding: 1.5rem 2rem;
    z-index: 2;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px #00000040;
    font-family: "Poppins";
}

.top-section {
    width: 100%;
    text-align: center;
    z-index: 2;
}

.top-section h3 {
    margin-block-start: 0px;
    margin-block-end: 0px;
    font-size: 1.8em;
    margin-bottom: 5px;
    color: rgba(71, 79, 133, 1);
}

.top-section p {
    margin-block-start: 15px;
    margin-block-end: 0px;
    font-size: 0.75em;
    font-weight: bold;
}

.input-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 8px 0px;
    z-index: 2;
}

.input-username {
    width: 94.5%;
    outline: none;
    border: 1px solid rgba(81, 227, 212, 1);
    background-color: rgba(81, 227, 212, 0.2);
}

.input-container span {
    width: 94.5%;
    height: fit-content;
    margin: 1rem 0px;
    display: grid;
    grid-template-columns: 80% 20%;
    border: 1px solid rgba(81, 227, 212, 1);
}

.input-password {
    outline: none;
    border: none;
    width: 100%;
    margin-top: 0px !important;
}

.toggle-password {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
}

.input-container span,
.input-username,
.signin-btn {
    padding: 10px 10px;
    border-radius: 10px;
    font-family: "Poppins";
    font-size: 0.75em;
}

.input-password {
    font-size: 1em !important;
    margin-bottom: 0px;
    font-family: "Poppins";
}

.input-username:focus,
.input-container span:focus {
    box-shadow: 0px 0px 3px 0px #008c97b5;
}

.signin-btn {
    border: none;
    background: #51E3D4;
    border: none;
    padding: 0.8rem 10px;
    width: 102%;
    box-shadow: 0px 4px 9px 1px #02097e2b;
    color: #474F85;
    font-weight: bold;
    cursor: pointer;
    transition: 0.4s;
}

.signin-btn:hover {
    transform: scale(1.03);
}

.bottom-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.bottom-section p {
    margin-block-start: 0px;
    margin-block-end: 0px;
    font-size: 0.55em;
}

.bottom-section p a {
    text-decoration: none;
    color: black;
    font-weight: bold;
}

.bottom-section p a:hover {
    text-decoration: underline;
}

.hidden {
    display: none !important;
}

#visible,
#invisible {
    color: rgba(81, 227, 212, 1);
}

@media screen and (max-width: 800px) {
    .links {
        padding-right: 1rem;
    }
}

@media screen and (max-width:400px) {
    .LoginBox {
        width: 80%;
        padding: 0.9rem;
        padding-right: 1.2rem;
    }
}
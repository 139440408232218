.page_visit_filter {
    position: absolute;
    top: -500%;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: fit-content;
    min-width: 500px;
    height: fit-content;
    border: 2px solid var(--themeColor);
    border-radius: 5px;
    box-shadow: 0px 0px 3px var(--themeColor);
    padding: 10px;
    background-color: white;
    display: flex;
    flex-direction: column;
    transition: 0.4s;
}

.page_visit_filter input,
select {
    outline: none !important;
    border: 2px solid var(--themeColor);
}

.page_visit_filter div {
    width: 100%;
}

.filter_name_section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.filter_name_section p {
    margin-block-start: 0px;
    margin-block-end: 0px;
    font-size: 1.5em;
    font-weight: bolder;
}

.filter_name_section button {
    cursor: pointer;
    background-color: transparent;
    border: none;
}

.page_visit_filter_input_section {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
}

.page_visit_filter_teacher_input_section{
    display: flex;
    flex-direction: column;
    width: 50% !important;
    height: fit-content;
}

.page_visit_filter_teacher_input_section select{
    border-radius: 5px !important;
}

.page_visit_filter_input_section span {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin: 2px 0px;
}

.page_visit_filter_input_section span span {
    display: flex;
    flex-direction: column;
    width: 49%;
}

.page_visit_filter_input_section span span select,
.filter_module_input {
    border-radius: 5px !important;
    padding: 1px;
}

.filter_module_input {
    margin-bottom: 5px;
}

.page_visit_filter_button_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
}

.page_visit_filter_button_container button {
    width: 49%;
    padding: 2px 0px;
    border-radius: 5px;
    border: 2px solid var(--themeColor);
    font-weight: bold;
    cursor: pointer;
}

.filter_clear_all_btn {
    background-color: transparent !important;
}

.filter_apply_btn {
    background-color: var(--themeColor) !important;
}
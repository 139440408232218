:root {
    --password-font-size: 20px;
}

.Password {
    flex: 4 1;
    padding: 20px;
    padding-right: 30px;
    display: flex;
    flex-direction: column;
}

.info-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.info-container span {
    margin-left: 1rem;
}

.info-container span p {
    margin-block-start: 0px;
    margin-block-end: 0px;
    font-weight: bold;
}

#school-name {
    font-size: 2em;
}

#school-address {
    font-size: 1.2em;
}

.password-container {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}

.password-container span {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}

.password-container span label {
    margin-bottom: 5px;
    font-size: var(--password-font-size);
}

.password-container span input {
    width: 15em;
    padding: 5px;
    font-size: var(--password-font-size);
    outline: none;
    border: 1.5px solid rgb(0, 158, 150);
}

.password-container span input:focus {
    box-shadow: 0px 0px 2px #007980;
}

.password-message {
    margin-block-start: 0px;
    font-size: 12px;
    color: rgb(0, 110, 105);
}

.save-password-btn {
    margin-top: 1rem;
    padding: 7px 20px;
    background-color: var(--themeColor);
    border: none;
    border-radius: 5px;
    font-size: 15px;
    transition: 0.4s;
}

.save-password-btn:hover {
    transform: scale(1.03);
}

@media screen and (max-width: 600px) {
    .info-container {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .info-container span {
        margin-left: 0px;
        margin-top: 10px;
    }
}

#edit-password-image {
    width: 70px;
    height: auto;
}
* {
    font-family: "Poppins";
}

.main {
    width: 100%;
    display: flex;
}

.MuiTableCell-root {
    font-family: "Poppins" !important;
}

.MuiTableCell-head {
    color: #5CE0D2 !important;
    font-weight: bold !important;
}

.name {
    font-weight: bold !important;
}

canvas {
    font-family: "Poppins" !important;
}

.searchNav-right-user img {
    width: 50px !important;
    height: auto;
}
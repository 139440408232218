:root {
    --password-font-size: 20px;
}

.RegistrationInfo {
    flex: 4 1;
    padding: 20px;
    padding-right: 30px;
    display: flex;
    flex-direction: column;
}

.information-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.information-container div {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.information-container div p,
.information-container div span p {
    margin-block-start: 0px;
    margin-block-end: 0px;
}

.information-container div p {
    font-weight: bold;
    font-size: 18px;
}

.information-container div span {
    margin-top: 5px;
    width: 80%;
    border-radius: 3px;
    background-color: rgb(247, 243, 243);
    border: 1px solid rgb(134, 134, 134);
}

.information-container div span p {
    padding: 5px;
    width: 100%;
    color: rgb(134, 134, 134);
    font-weight: normal;
}